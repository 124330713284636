import axios from 'axios'
import qs from 'qs'
import axiosRetry from 'axios-retry'
import { isCancellable } from '@/api/interceptor.js'

window.abortController = new AbortController()

export const instance = axios.create({
  baseURL: '/',
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json'
  },
  paramsSerializer: {
    serialize: (params) => qs.stringify(params, { skipNulls: true, indices: false }) // to get: param=value1&param=value2
  }
})

export const oauthInstance = axios.create({
  baseURL: '/',
  timeout: 10000,
  headers: {
    'Content-Type': 'multipart/form-data'
  }
})

instance.interceptors.request.use((config) => {
  if (isCancellable(config)) {
    const url = axios.getUri(config)

    const abortController = new AbortController()
    window.abortControllers[url] = abortController
    config.signal = abortController.signal
  }
  return config
})

instance.interceptors.response.use(async (response) => {
  const { status, config } = response
  if (status === 202 && !config.skip202) {
    const err = new Error('Pending response')
    err.config = config
    err.response = response

    throw err
  }
  return response
})

instance.interceptors.response.use(async (response) => {
  if (response.status !== 202) {
    const url = axios.getUri(response.config)
    delete window.abortControllers[url]
  }
  return response
})

axiosRetry(instance, {
  retries: 100,
  retryDelay: () => 1000,
  shouldResetTimeout: true,
  retryCondition(error) {
    const url = axios.getUri(error.config)
    const stillNecessary = url in window.abortControllers
    const isPending = error.message === 'Pending response'
    return stillNecessary && isPending
  }
})

export default instance
