export default {
  mounted(el, binding) {
    const shortcut = binding.value.toLowerCase()
    const split = shortcut.split('+')

    const requiresCtrl = split.includes('ctrl')
    const requiresShift = split.includes('shift')

    const actionKey = split.pop()

    el._handleKeyboardShortcut = (e) => {
      const {
        ctrlKey,
        shiftKey,
        key,
        target: { tagName }
      } = e

      if (['INPUT', 'TEXTAREA'].includes(tagName)) {
        return
      }

      // Stop if modifiers requirement are not met.
      if ((requiresCtrl && !ctrlKey) || (requiresShift && !shiftKey)) {
        return
      }

      if (key.toLowerCase() === actionKey) {
        e.preventDefault()

        const disabled = el.getAttribute('disabled') === 'disabled'

        if (!disabled) {
          el.click(e)
          if (el.getAttribute('data-oruga') === 'input') {
            el.querySelector('input').focus()
          }
        }
      }
    }

    document.addEventListener('keydown', el._handleKeyboardShortcut)
  },

  unmounted(el) {
    document.removeEventListener('keydown', el._handleKeyboardShortcut)
  }
}
