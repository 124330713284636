import providerAccountsApi from '@/api/organizations/provider-accounts'
import { defineStore } from 'pinia'
import instance from '@/api/index.js'
import moment from 'moment'
import MergeDiagramsWorker from '@/workers/merge-diagrams.js?worker'
import { calculatePagination } from '@/common/paginate.js'
import { getAWSColumnsIndexes } from '@/common/cost-dashboard/utils.js'
import CURtoCE from '@/common/cur-to-ce.json'
import { extractFulfilledValues } from '@/common/utils.js'

const initialState = {
  providerAccounts: [],
  subdivisions: {},
  total: 0,
  providerAccount: null,

  synchronizations: [],
  synchronizationPagination: {
    current: 1,
    total: 0
  },
  synchronizationDiagram: null,
  synchronizationThumbnail: null,

  loading: {
    synchronizations: false,
    synchronizationDiagram: false,
    synchronizationThumbnail: false,
    providerAccounts: {}
  }
}

const emptyDiagram = { nodes: [], edges: [], groups: [] }

export const useProviderAccountsStore = defineStore({
  id: 'provider-account',

  state: () => initialState,

  getters: {
    lastSynchronizationDate: ({ synchronizations }) => synchronizations[0]?.imported,
    getAccountByID: ({ providerAccounts }) => (id) => providerAccounts.find((providerAccount) => providerAccount.id === id)
  },

  actions: {
    /**
     * Get a list of provider accounts in this organization.
     * @param {Number} organizationID The organization ID.
     * @param {Object} filters `name`, `provider_name`, `enabled`, `created_since`, `created_until`, `offset`, `limit`
     * @returns {Promise<Array<Object>>}
     */
    getProviderAccounts(organizationID, filters = {}) {
      if ('provider_name' in filters) {
        this.loading.providerAccounts[filters.provider_name] = true
      }
      return new Promise((resolve, reject) => {
        providerAccountsApi.getProviderAccounts(organizationID, filters).then(
          (res) => {
            if ('provider_name' in filters) {
              this.loading.providerAccounts[filters.provider_name] = false
            }

            const azureAccounts = res.data.filter((acc) => acc.provider_name === 'azure')
            azureAccounts.forEach((acc) => {
              // this.getAzureSubscriptions(organizationID, acc)
            })

            this.providerAccounts = res.data
            this.total = Number(res.headers['content-range'].split('/')[1])
            resolve(res.data)
          },
          (err) => reject(err)
        )
      })
    },

    getAzureSubscriptions(organizationID, account) {
      return new Promise((resolve) => {
        const hereAccount = this.providerAccounts.find((acc) => acc.id === account.id)
        if (hereAccount && 'subscriptions' in hereAccount) {
          resolve(hereAccount.subscriptions)
          return
        }

        providerAccountsApi
          .getAzureSubscriptions(organizationID, account.id)
          .then(({ data: subscriptions }) => {
            const accountIndex = this.providerAccounts.findIndex((acc) => acc.id === account.id)
            this.providerAccounts[accountIndex] = {
              ...account,
              subscriptions
            }
            resolve(subscriptions)
          })
      })
    },

    /**
     * Create a new provider account in this organization.
     * @param {Number} organizationID The organization ID.
     * @param {Object} data `name`, `enabled`, `provider_name`, `authentication_data`
     * @returns {Promise<AxiosResponse>}
     */
    createProviderAccount(organizationID, data) {
      this.providerAccount = null

      return new Promise((resolve, reject) => {
        providerAccountsApi.createProviderAccount(organizationID, data).then(
          (res) => {
            this.providerAccount = res.data
            resolve(res)
          },
          (err) => reject(err)
        )
      })
    },

    /**
     * Edit a provider account in this organization.
     * @param {Number} organizationID The organization ID.
     * @param {Number} accountID The provider account ID.
     * @param {Object} data `name`, `enabled`, `provider_name`, `authentication_data`
     * @returns {Promise<AxiosResponse>}
     */
    editProviderAccount(organizationID, accountID, data) {
      return new Promise((resolve, reject) => {
        providerAccountsApi.editProviderAccount(organizationID, accountID, data).then(
          (res) => resolve(res),
          (err) => reject(err)
        )
      })
    },

    setProviderAccount(account) {
      this.providerAccount = account
    },

    /**
     * Get a provider account in this organization.
     * @param {Number} organizationID The organization ID.
     * @param {Number} accountID The provider account ID.
     * @returns {Promise<AxiosResponse>}
     */
    getProviderAccount(organizationID, accountID) {
      return new Promise((resolve, reject) => {
        providerAccountsApi.getProviderAccount(organizationID, accountID).then(
          ({ data }) => {
            this.providerAccount = data
            resolve(data)
          },
          (err) => reject(err)
        )
      })
    },

    /**
     * Delete a provider account in this organization.
     * @param {Number} organizationID The organization ID.
     * @param {Number} accountID The provider account ID.
     * @returns {Promise<AxiosResponse>}
     */
    deleteProviderAccount(organizationID, accountID) {
      return new Promise((resolve, reject) => {
        providerAccountsApi.deleteProviderAccount(organizationID, accountID).then(
          () => {
            resolve()
          },
          (err) => reject(err)
        )
      })
    },

    /**
     * Verify a provider account in this organization.
     * @param {Number} organizationID The organization ID.
     * @param {Number} accountID The provider account ID.
     * @returns {Promise<AxiosResponse>}
     */
    verifyProviderAccount(organizationID, accountID) {
      return new Promise((resolve, reject) => {
        providerAccountsApi.verifyProviderAccount(organizationID, accountID).then(
          (res) => {
            resolve(res.data)
          },
          (err) => reject(err)
        )
      })
    },

    /**
     * Get synchronizations for provider account.
     * @param {Number} organizationID The organization ID.
     * @param {Number} accountID The provider account ID.
     * @param {Object} params Offset/limit.
     * @returns {Promise<Array<Object>>}
     */
    getSynchronizations(organizationID, accountID, params = {}) {
      this.synchronizations = []
      this.loading.synchronizations = true

      return new Promise((resolve) => {
        providerAccountsApi.getSynchronizations(organizationID, accountID, params).then((res) => {
          const { headers, data } = res
          this.synchronizations = data
          this.loading.synchronizations = false
          this.synchronizationPagination = calculatePagination(headers)
          resolve(data)
        })
      })
    },

    /**
     * Get the latest synchronization from a provider account.
     * @param {Number} organizationID The organization ID.
     * @param {Number} accountID The provider account ID.
     * @returns {Promise<Object>}
     */
    getLastSynchronization(organizationID, accountID) {
      this.synchronizations = []
      this.loading.synchronizations = true

      return new Promise((resolve) => {
        providerAccountsApi.getLastSynchronization(organizationID, accountID).then(({ data }) => {
          const sync = data[0]
          this.synchronizations = data
          this.loading.synchronizations = false
          resolve(sync)
        })
      })
    },

    /**
     * Update the synchronizations.
     * @param {Array<Object>} synchronizations
     */
    setSynchronizations(synchronizations) {
      this.synchronizations = synchronizations
    },

    /**
     * Get a synchronization entry.
     * @param {Number} organizationID The organization ID.
     * @param {Number} accountID The provider account ID.
     * @param {Number} synchronizationID The synchronization ID.
     * @returns {Promise<Object>}
     */
    getSynchronization(organizationID, accountID, synchronizationID) {
      return new Promise((resolve, reject) => {
        providerAccountsApi.getSynchronization(organizationID, accountID, synchronizationID).then(
          ({ data }) => {
            resolve(data)
          },
          () => reject(new Error('Could not get synchronization.'))
        )
      })
    },

    /**
     * Get the diagram for a synchronization entry.
     * @param {Number} organizationID The organization ID.
     * @param {Number} accountID The provider account ID.
     * @param {Number} synchronizationID The synchronization ID.
     * @returns {Promise<String>}
     */
    getSynchronizationDiagram(organizationID, accountID, synchronizationID) {
      this.synchronizationDiagram = null
      this.loading.synchronizationDiagram = true

      return new Promise((resolve, reject) => {
        providerAccountsApi
          .getSynchronizationDiagram(organizationID, accountID, synchronizationID)
          .then(
            ({ data }) => {
              let { diagram } = data
              if (!diagram) {
                diagram = JSON.stringify(emptyDiagram)
              }
              this.synchronizationDiagram = diagram
              this.loading.synchronizationDiagram = false
              resolve(diagram)
            },
            () => reject(new Error('Could not get synchronization diagram.'))
          )
      })
    },

    /**
     * Set the synchronization diagram.
     * @param {Object} synchronizationDiagram The diagram data.
     */
    setSynchronizationDiagram(synchronizationDiagram) {
      this.synchronizationDiagram = synchronizationDiagram
    },

    /**
     * Get the thumbnail for a synchronization entry.
     * @param {Number} organizationID The organization ID.
     * @param {Number} accountID The provider account ID.
     * @param {Number} synchronizationID The synchronization ID.
     * @returns {Promise<Object>}
     */
    getSynchronizationThumbnail(organizationID, accountID, synchronizationID) {
      this.synchronizationThumbnail = null
      this.loading.synchronizationThumbnail = true

      return new Promise((resolve, reject) => {
        providerAccountsApi
          .getSynchronizationThumbnail(organizationID, accountID, synchronizationID)
          .then(
            ({ data }) => {
              this.synchronizationThumbnail = data.thumbnail
              this.loading.synchronizationThumbnail = false
              resolve(data.thumbnail)
            },
            () => reject(new Error('Could not get synchronization thumbnail.'))
          )
      })
    },

    setSynchronizationThumbnail(synchronizationThumbnail) {
      this.synchronizationThumbnail = synchronizationThumbnail
    },

    /**
     * Edit a synchronization.
     * @param {Number} organizationID
     * @param {Number} accountID
     * @param {Number} synchronizationID
     * @param {{ thumbnail: String, diagram: String }} payload
     * @returns {Promise<AxiosResponse<any>>}
     */
    editSynchronization(organizationID, accountID, synchronizationID, payload) {
      return providerAccountsApi.editSynchronization(
        organizationID,
        accountID,
        synchronizationID,
        payload
      )
    },

    getNumberOfAssets(organizationID, accountID) {
      return new Promise((resolve) => {
        this.getLastSynchronization(organizationID, accountID).then((sync) => {
          this.getSynchronizationDiagram(organizationID, accountID, sync.id).then((diagram) => {
            const parsed = JSON.parse(diagram)
            const items = [...parsed.nodes, ...parsed.groups]
            const numberOfAssets = items.filter(
              (item) => item.type !== 'TextBox' && item.type !== 'text-box'
            ).length
            resolve(numberOfAssets)
          })
        })
      })
    },

    /**
     * Get cost and usage
     * @param {Number} organizationID The organization ID.
     * @param {Number} accountID The provider account ID.
     * @param {Object} filters `granularity`, `filters`, `group_by`, `start_date`, `end_date`
     * @param {Object} rules
     * @returns {Promise<AxiosResponse>}
     */
    getCost(organizationID, accountID, filters = {}, rules = {}) {
      return providerAccountsApi.getCost(organizationID, accountID, filters, rules)
    },

    getAWSCost(organizationID, accountID, filters = {}, rules = {}) {
      const account = this.getAccountByID(accountID)
      const accountDoesHaveCur = ('use_cur_only' in (account?.authentication_data || {}))
      const accountIsCurOnly = account?.authentication_data.use_cur_only

      const promises = []

      if (accountDoesHaveCur && account?.authentication_data?.feature !== 'diagrams') {
        promises.push(
          // Regular cost request is AWS's cur.
          new Promise((resolve) => {
            providerAccountsApi.getCost(organizationID, accountID, filters, rules).then(
              ({ data}) => {
                if (!Array.isArray(data)) {
                  resolve({ cur: [] })
                } else {
                  const indexes = getAWSColumnsIndexes(data)
                  const cur = []
                  for (const row of data.slice(1)) {
                    const cost = parseFloat(row[indexes.cost])
                    const date = row[indexes.date]
                    const currency = row[indexes.currency]
                    const resourceID = indexes.resourceID > -1 ? row[indexes.resourceID] : undefined
                    const subaccount = indexes.subaccount > -1 ? row[indexes.subaccount] : undefined
                    let service = indexes.service > -1 ? row[indexes.service] : undefined
                    const region = indexes.location > -1 ? (row[indexes.location] || 'Other') : undefined
                    const usageType = indexes.usageType > -1 ? row[indexes.usageType] : undefined
                    const foundTags = indexes.tags > -1 ? JSON.parse(row[indexes.tags]) : {}

                    // Try to convert CUR name to CE name
                    if (service in CURtoCE) {
                      service = CURtoCE[service]
                    }

                    const tags = []

                    for (const key in foundTags) {
                      tags.push({ key, value: foundTags[key] })
                    }

                    cur.push({
                      cost,
                      date,
                      currency,
                      resourceID,
                      subaccount,
                      service,
                      region,
                      location: region, // We never know
                      usageType,
                      tags
                    })
                  }

                  resolve({ cur })
                }
              }, () => {
                resolve({ cur: [] })
              })
          })
        )
      }

      if (filters.group_by !== 'RESOURCE_ID' && !accountIsCurOnly) {
        promises.push(
          new Promise((resolve) => {
            providerAccountsApi.getCost(organizationID, accountID, filters, rules, true).then(
              ({ data }) => {
                const costExplorer = []

                for (const row of data) {
                  const date = row.TimePeriod.Start

                  for (const group of row.Groups) {
                    const [key] = group.Keys
                    const { Metrics: { UnblendedCost: { Amount, Unit: currency } } } = group
                    const cost = parseFloat(Amount)
                    costExplorer.push({
                      cost,
                      date,
                      currency,
                      key
                    })
                  }
                }
                resolve({ costExplorer })
              }, () => {
                resolve({ costExplorer: [] })
              })
          })
        )
      }

      const curKeysMapping = {
        'SERVICE': 'service',
        'USAGE_TYPE': 'usageType',
        'REGION': 'region',
        'RESOURCE_ID': 'resourceID'
      }

      return new Promise((bigResolve) => {
        Promise.allSettled(promises).then((results) => {
          const values = results.map(({ value }) => value)
          const cur = values.find((obj) => 'cur' in obj)?.cur || []
          const costExplorer = values.find((obj) => 'costExplorer' in obj)?.costExplorer || []
          const [firstCurRow] = cur
          const curDate = firstCurRow?.date
          let aggregated = []

          for (let i = 0; i < costExplorer.length; i++) {
            const row = costExplorer[i]
            const { date, key, cost, currency } = row

            if (curDate && date >= curDate) {
              break
            }

            aggregated.push({
              cost,
              date,
              currency,
              [curKeysMapping[filters.group_by]]: key
            })
          }

          aggregated = aggregated.concat(cur)
          bigResolve({ data: aggregated, config: { params: { group_by: filters.group_by } } })
        })
      })
    },

    /**
     * Get regions.
     * @param {Number} organizationID The organization ID.
     * @param {Number} accountID The provider account ID.
     * @param {String} startDate The start date.
     * @param {String} endDate The end date.
     * @param {Object} filters The filters.
     * @returns {Promise<AxiosResponse>}
     */
    getRegions(organizationID, accountID, startDate, endDate, filters) {
      const account = this.getAccountByID(accountID)
      const { provider_name: provider, authentication_data } = account

      const request = providerAccountsApi.getRegions(organizationID, accountID, startDate, endDate, filters)

      if (provider !== 'aws') {
        return request
      }

      return new Promise((resolve) => {
        const fromCUR = new Promise((resolveCUR) => {
          request.then(({ data }) => {
            resolveCUR(data.slice(1).map((row) => row[0]))
          }, () => resolveCUR([]))
        })

        const fromCostExplorer = new Promise((resolveCostExplorer) => {
          providerAccountsApi.getCostExplorerRegions(organizationID, accountID, startDate, endDate, filters).then(
            ({ data }) => {
              const regions = data.map(({ Value }) => Value)
              resolveCostExplorer(regions)
            }, () => resolveCostExplorer([]))
        })

        const promises = [fromCUR]

        if (!authentication_data.use_cur_only) {
          promises.push(fromCostExplorer)
        }

        Promise.allSettled(promises).then((results) => {
          const { allData } = extractFulfilledValues(results)
          const data = Array.from(new Set(allData.flat())).filter((val) => !!val)
          resolve({ data })
        }, () => resolve({ data: [] }))
      })
    },

    /**
     * Get available services for provider account.
     * @param {Number} organizationID The ID of the organization.
     * @param {Number} accountID The ID of the provider account.
     * @param {String} startDate The start date.
     * @param {String} endDate The end date.
     * @param {Object} filters The filters.
     * @returns {Promise<AxiosResponse<any>>}
     */
    getServices(organizationID, accountID, startDate, endDate, filters) {
      const account = this.getAccountByID(accountID)
      const { provider_name: provider, authentication_data } = account

      const request = providerAccountsApi.getServices(organizationID, accountID, startDate, endDate, filters)

      if (provider !== 'aws') {
        return request
      }

      return new Promise((resolve) => {
        const fromCUR = new Promise((resolveCUR) => {
          request.then(({ data }) => {
            const services = data.slice(1)
              .map((row) => row[0] in CURtoCE ? CURtoCE[row[0]] : row[0])
            resolveCUR(services)
          }, () => resolveCUR([]))
        })

        const fromCostExplorer = new Promise((resolveCostExplorer) => {
          providerAccountsApi.getCostExplorerServices(organizationID, accountID, startDate, endDate, filters).then(
            ({ data }) => {
              const services = data.map(({ Value }) => Value)
              resolveCostExplorer(services)
            }, () => resolveCostExplorer([]))
        })

        const promises = [fromCUR]

        if (!authentication_data.use_cur_only) {
          promises.push(fromCostExplorer)
        }

        Promise.allSettled(promises).then((results) => {
          const { allData } = extractFulfilledValues(results)
          const data = Array.from(new Set(allData.flat()))
          resolve({ data })
        }, () => resolve({ data: [] }))
      })
    },

    /**
     * Get available resources names for provider account.
     * @param {Number} organizationID The ID of the organization.
     * @param {Number} accountID The ID of the provider account.
     * @param {String} startDate The start date.
     * @param {String} endDate The end date.
     * @param {Object} filters The filters.
     * @returns {Promise<AxiosResponse<any>>}
     */
    getResources(organizationID, accountID, startDate, endDate, filters) {
      const account = this.getAccountByID(accountID)
      const { provider_name: provider, authentication_data } = account

      const request = providerAccountsApi.getResources(
        organizationID,
        accountID,
        startDate,
        endDate,
        filters
      )

      if (provider !== 'aws') {
        return request
      }

      return new Promise((resolve) => {
        const fromCUR = new Promise((resolveCUR) => {
          request.then(({ data }) => {
            const resources = data.slice(1).map((row) => row[0])
            resolveCUR(resources)
          }, () => resolveCUR([]))
        })

        const fromCostExplorer = new Promise((resolveCostExplorer) => {
          providerAccountsApi.getCostExplorerResources(organizationID, accountID, startDate, endDate, filters).then(
            ({ data }) => {
              const resources = data.map(({ Value }) => Value)
              resolveCostExplorer(resources)
            }, () => resolveCostExplorer([]))
        })

        const promises = [fromCUR]

        if (!authentication_data.use_cur_only) {
          promises.push(fromCostExplorer)
        }

        Promise.allSettled(promises).then((results) => {
          const { allData } = extractFulfilledValues(results)
          const data = Array.from(new Set(allData.flat())).filter((val) => !!val)
          resolve({ data })
        }, () => resolve({ data: [] }))
      })
    },

    /**
     * Get cost forecast.
     * @param {Number} organizationID The organization ID.
     * @param {Number} accountID The provider account ID.
     * @param {Object} filters `granularity`, `filters`, `group_by`, `start_date`, `end_date`
     * @returns {Promise<AxiosResponse>}
     */
    getCostForecast(organizationID, accountID, filters = {}) {
      return providerAccountsApi.getCostForecast(organizationID, accountID, filters)
    },

    /**
     * Get tags.
     * @param {Number} organizationID The organization ID.
     * @param {Number} accountID The provider account ID.
     * @param {String} startDate Start date.
     * @param {String} endDate End date.
     * @param {Object} filters `granularity`, `filters`, `group_by`, `start_date`, `end_date`
     * @returns {Promise<AxiosResponse>}
     */
    getTags(organizationID, accountID, startDate, endDate, filters) {
      return providerAccountsApi.getTags(organizationID, accountID, startDate, endDate, filters)
    },

    search(organizationID, accountID, resourceID) {
      return providerAccountsApi.search(organizationID, accountID, resourceID)
    },

    /**
     * Get Azure resource details.
     * @param {Number} organizationID The ID of the organization.
     * @param {Number} accountID The ID of the provider account.
     * @param {String} resourceID Resource ID from the provider.
     * @returns {Promise<AxiosResponse<any>>}
     */
    getAzureDetails(organizationID, accountID, resourceID) {
      return providerAccountsApi.getAzureDetails(organizationID, accountID, resourceID)
    },

    /**
     * Get dimension values.
     * @param {Number} organizationID The ID of the organization.
     * @param {Number} accountID The ID of the provider account.
     * @param {String} provider The provider (`aws`, `gcp`, etc.).
     * @param {String} dimension The dimension to get.
     * @returns {Promise<AxiosResponse<any>>}
     */
    getDimensionValues(organizationID, accountID, provider, dimension) {
      return providerAccountsApi.getDimensionValues(organizationID, accountID, provider, dimension)
    },

    loadDemoDetails(skipThumbnail = false) {
      return new Promise((resolve) => {
        instance.get('/example-infrastructures/aws.json').then(({ data }) => {
          this.setProviderAccount({
            id: 'demo',
            name: 'Demo Account',
            autosync: false
          })
          this.setSynchronizationDiagram(data.diagram)

          if (!skipThumbnail) {
            this.setSynchronizationThumbnail(data.thumbnail)
          }

          this.setSynchronizations([
            {
              imported: moment().format('YYYY-MM-DD')
            }
          ])

          resolve()
        })
      })
    },

    getProviderDiagram(organizationID, provider, strict = true) {
      const appendMetadata = (accountID) => {
        return (el) => ({
          ...el,
          accountID,
          provider
        })
      }

      return new Promise((bigResolve, bigReject) => {
        providerAccountsApi
          .getProviderAccounts(organizationID, { provider_name: provider })
          .then(({ data: accounts }) => {
            if (!accounts.length) {
              bigReject(`No matching accounts for provider ${provider}.`)
              return
            }

            const accountsPromises = accounts.map((account) => {
              const { id: accountID } = account
              return new Promise((resolveAccount) => {
                providerAccountsApi
                  .getLastSynchronization(organizationID, accountID)
                  .then(({ data: synchronizations }) => {
                    const [lastSynchronization] = synchronizations
                    // No last synchronization, resolve empty data.
                    if (!lastSynchronization) {
                      resolveAccount(emptyDiagram)
                    } else {
                      providerAccountsApi
                        .getSynchronizationDiagram(
                          organizationID,
                          accountID,
                          lastSynchronization.id
                        )
                        .then(({ data: { diagram } }) => {
                          if (!diagram) {
                            return resolveAccount(emptyDiagram)
                          }
                          const jsonDiagram = JSON.parse(diagram)
                          const { nodes, edges, groups } = jsonDiagram
                          const transformedNodes = nodes.map(appendMetadata(accountID))
                          const transformedEdges = edges.map(appendMetadata(accountID))
                          const transformedGroups = groups.map(appendMetadata(accountID))
                          const allElements = [
                            ...transformedNodes,
                            ...transformedGroups,
                            ...transformedEdges
                          ]
                          let transformedDiagram = JSON.stringify({
                            nodes: transformedNodes,
                            edges: transformedEdges,
                            groups: transformedGroups
                          })
                          // Gather all the IDs of the elements...
                          const allIDs = allElements.map(({ id }) => id)

                          // Then replace them everywhere.
                          for (const id of allIDs) {
                            const transformedID = id + '-' + accountID
                            transformedDiagram = transformedDiagram.replaceAll(id, transformedID)
                          }

                          resolveAccount(JSON.parse(transformedDiagram))
                        })
                    }
                  })
              })
            })

            Promise.allSettled(accountsPromises).then((results) => {
              const diagrams = results.map(({ value }) => value)
              if (!strict) {
                const result = { nodes: [], edges: [], groups: [] }
                for (const diagram of diagrams) {
                  result.nodes = result.nodes.concat(diagram.nodes)
                  result.edges = result.edges.concat(diagram.edges)
                  result.groups = result.groups.concat(diagram.groups)
                }
                bigResolve(result)
              } else {
                const worker = new MergeDiagramsWorker()
                worker.onmessage = function(e) {
                  bigResolve(e.data)
                }
                worker.postMessage(diagrams)
              }
            })
          })
      })
    },

    getRightsizingRecommendations(organizationID, accountID) {
      return providerAccountsApi.getRightsizingRecommendations(organizationID, accountID)
    },

    getDetails(organizationID, accountID, resourceID, provider) {
      return providerAccountsApi.getDetails(organizationID, accountID, resourceID, provider)
    },

    getSubdivisions(accountID) {
      if (accountID in this.subdivisions) {
        return this.subdivisions[accountID]
      }
      return null
    },

    /**
     * Get GCP account "projects" subdivision.
     * @param {Number} organizationID The organization ID.
     * @param {Number} accountID The account ID of which to fetch the "projects".
     * @returns {Promise<Array<Object>>}
     */
    getGCPProjects(organizationID, accountID) {
      return new Promise((resolve) => {
        const found = this.getSubdivisions(accountID)
        if (found) {
          return resolve(found)
        }
        providerAccountsApi.getGCPProjects(organizationID, accountID).then((res) => {
          this.subdivisions[accountID] = res.data
          resolve(res.data)
        })
      })
    },

    /**
     * Get AWS account "accounts" subdivision.
     * @param {Number} organizationID The organization ID.
     * @param {Number} accountID The account ID of which to fetch the "accounts".
     * @returns {Promise<Array<Object>>}
     */
    getAWSAccounts(organizationID, accountID) {
      return new Promise((resolve) => {
        const found = this.getSubdivisions(accountID)
        if (found) {
          return resolve(found)
        }
        providerAccountsApi.getAWSAccounts(organizationID, accountID).then((res) => {
          this.subdivisions[accountID] = res.data
          resolve(res.data)
        })
      })
    }
  }
})
