import instance from '@/api'
import { organizationsApiUrl } from '@/api/organizations/index.js'

export default {
  /**
   * Get the custom dashboards for an organization.
   * @param {Number} organizationID The organization ID.
   * @returns {Promise<AxiosResponse>}
   */
  getDashboards(organizationID) {
    return instance.get(`${organizationsApiUrl}/${organizationID}/dashboards`)
  },

  /**
   * Get a custom dashboard for an organization.
   * @param {Number} organizationID The organization ID.
   * @param {Number} dashboardID The dashboard ID.
   * @returns {Promise<AxiosResponse>}
   */
  getDashboard(organizationID, dashboardID) {
    return instance.get(`${organizationsApiUrl}/${organizationID}/dashboards/${dashboardID}`)
  },

  /**
   * Create a custom dashboard for an organization.
   * @param {Number} organizationID The organization ID.
   * @param {{ name: String, tiles: Array<Object> }} payload
   */
  createDashboard(organizationID, payload) {
    return instance.post(`${organizationsApiUrl}/${organizationID}/dashboards`, {
      ...payload,
      tiles: payload.tiles.map((t) => typeof t === 'string' ? t : JSON.stringify(t))
    })
  },

  /**
   * Patch a custom dashboard for an organization.
   * @param {Number} organizationID The organization ID.
   * @param {Number} dashboardID The dashboard ID.
   * @param {{ name: String, tiles: Array<Object> }} payload
   */
  patchDashboard(organizationID, dashboardID, payload) {
    return instance.patch(`${organizationsApiUrl}/${organizationID}/dashboards/${dashboardID}`, {
      ...payload,
      tiles: payload.tiles.map((t) => typeof t === 'string' ? t : JSON.stringify(t))
    })
  },

  /**
   * Delete a custom dashboard.
   * @param {Number} organizationID The organization ID.
   * @param {Number} dashboardID The dashboard ID.
   */
  deleteDashboard(organizationID, dashboardID) {
    return instance.delete(`${organizationsApiUrl}/${organizationID}/dashboards/${dashboardID}`)
  }
}
