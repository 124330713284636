import { defineStore } from 'pinia'
import recommendationsApi from '@/api/organizations/recommendations'
import { useDashboardStore } from '@/store/dashboard.js'
import { extractFulfilledValues } from '@/common/utils.js'
import { useProviderAccountsStore } from '@/store/provider-accounts.js'
import moment from 'moment'
import { RECOMMENDATIONS_CATEGORIES } from '@/common/cost-dashboard/recommendations.js'
import { useAppStore } from '@/store/app.js'

export const SUPPORTED_PROVIDERS = ['aws', 'azure', 'gcp', 'oci']

export const useRecommendationsStore = defineStore({
  id: 'recommendations',

  state: () => ({
    loading: {
      costs: true,
      recommendations: true
    },
    period: 'monthly',
    costs: [],
    recommendations: {},
    savingsPlans: {},
    unusedCosts: {}
  }),

  getters: {
    allSavingsPlans: ({ savingsPlans }) => Object.values(savingsPlans).flat(),
  },

  actions: {
    setPeriod(period) {
      this.period = period
    },

    getRecommendations(category, subcategory, loadMore = false, service = undefined) {
      this.loading.recommendations = true

      const dashboardStore = useDashboardStore()
      const appStore = useAppStore()

      return new Promise((bigResolve, bigReject) => {
        if (!RECOMMENDATIONS_CATEGORIES.includes(category)) {
          bigReject(`${category} is not a valid category.`)
        }

        this.recommendations[category] = { [subcategory]: [] }

        const promises = dashboardStore.filteredProviderAccounts.filter(({ provider_name }) => SUPPORTED_PROVIDERS.includes(provider_name))
          .map((account) => {
          const { organization_id, provider_name: provider, id, name } = account
          return new Promise((resolve, reject) => {
            recommendationsApi
              .getRecommendations(organization_id, provider, id, category, subcategory)
              .then(
                (res) => {
                  const { id, name, provider_name } = account

                  if (loadMore && subcategory === 'unused') {
                    this.getUnusedCosts(account, res.data, service)
                  }

                  resolve({
                    provider,
                    account: {
                      id,
                      name,
                      provider_name
                    },
                    data: res.data
                  })
                },
                () => {
                  reject(`Could not get recommendations for ${name}`)
                }
              )
              .finally(() => appStore.increaseValLoading(1))
          })
        })

        appStore.increaseMaxLoading(promises.length)

        Promise.allSettled(promises).then((results) => {
          const { allData } = extractFulfilledValues(results)
          this.loading.recommendations = false
          this.recommendations[category][subcategory] = allData
          bigResolve(allData)
        })
      })
    },

    getCosts() {
      const appStore = useAppStore()

      this.loading.costs = true

      const dashboardStore = useDashboardStore()
      const providerAccountsStore = useProviderAccountsStore()

      const today = moment()
      const last5Months = moment().subtract(5, 'months')
      // For OCI.
      const last3Months = moment().subtract(93, 'days')

      const filters = {
        group_by: 'SERVICE',
        start_date: last5Months.format('YYYY-MM-DD'),
        end_date: today.format('YYYY-MM-DD')
      }

      const filtersOCI = {
        ...filters,
        start_date: last3Months.format('YYYY-MM-DD')
      }

      return new Promise((bigResolve) => {
        const promises = dashboardStore.filteredProviderAccounts
          .filter(({ provider_name }) => SUPPORTED_PROVIDERS.includes(provider_name))
          .map(
          (account) =>
            new Promise((resolve) => {
              const call = account.provider_name === 'aws' ? 'getAWSCost' : 'getCost'
              providerAccountsStore
                [call](account.organization_id, account.id, account.provider_name === 'oci' ? filtersOCI : filters)
                .then(({ data }) => {
                  resolve({ account, data })
                }, () => {
                  resolve({ account, data: [] })
                })
                .finally(() => appStore.increaseValLoading())
            })
        )

        appStore.increaseMaxLoading(promises.length)

        Promise.allSettled(promises).then((results) => {
          const { allData } = extractFulfilledValues(results)
          this.loading.costs = false
          this.costs = allData
          bigResolve(allData)
        })
      })
    },

    getAWSSavingsPlans(type) {
      const appStore = useAppStore()
      const dashboardStore = useDashboardStore()

      return new Promise((bigResolve) => {
        const promises = dashboardStore.filteredProviderAccounts.map(
          (account) =>
            new Promise((resolve) =>
              recommendationsApi
                .getAWSSavingsPlans(account.organization_id, account.id, type)
                .then((res) => {
                  const { id, name, provider_name } = account
                  resolve({
                    provider: 'aws',
                    account: {
                      id,
                      name,
                      provider_name
                    },
                    data: res.data
                  })
                })
                .finally(() => appStore.increaseValLoading())
        ))

        Promise.allSettled(promises).then((results) => {
          const { allData } = extractFulfilledValues(results)
          this.savingsPlans[type] = allData
          bigResolve(allData)
        })
      })
    },

    getUnusedCosts(account, data, service = undefined) {
      const appStore = useAppStore()

      const resourceIDs = new Set()
      const { id: accountID, organization_id: organizationID, provider_name: provider } = account

      const providerAccountsStore = useProviderAccountsStore()

      for (const recommendation of data) {
        let foundService, resourceID
        if (provider === 'gcp') {
          foundService = recommendation.name.match(/google\.[a-zA-Z]+\.([a-zA-Z]+)/).pop()
          resourceID = recommendation.targetResources[0]
        } else if (provider === 'azure') {
          foundService = recommendation.impacted_field
          resourceID = recommendation.resource_metadata.resource_id
        } else if (provider === 'oci') {
          foundService = recommendation.resource_type
          resourceID = recommendation.resource_id
        }
        if ((service && foundService === service) || !service) {
          resourceIDs.add(resourceID)
        }
      }

      const arr = Array.from(resourceIDs)

      for (const resourceID of arr) {
        const rules = {
          resource_name: {
            operator: 'is',
            value: resourceID
          }
        }

        const startDate = moment().subtract(account.provider_name === 'aws' ? 14 : 30, 'days').format('YYYY-MM-DD')
        const endDate = moment().format('YYYY-MM-DD')

        appStore.increaseMaxLoading()

        providerAccountsStore.getCost(organizationID, accountID, {
          start_date: startDate,
          end_date: endDate
        }, rules).then((res) => {
          this.unusedCosts[resourceID] = res.data
        }).finally(appStore.increaseValLoading)
      }
    },

    clear() {
      this.costs = []
      this.recommendations = {}
    }
  }
})
