import { defineStore } from 'pinia'
import customDashboardsApi from '@/api/organizations/custom-dashboards'

export const defaultWidget = {
  name: '',
  dataType: 'cost',
  settings: {
    period: 'Last 30 days',
    granularity: 'daily',
    chartType: 'bar',
    view: null,
    filters: {},
    groupBy: 'SERVICE'
  }
}

export const defaultTiles = [[defaultWidget]]

export const defaultDashboard = {
  name: '',
  tiles: defaultTiles
}

const useCustomDashboardStore = defineStore('custom-dashboard', {
  state: () => ({
    dashboards: [],
    dashboard: null,

    pins: []
  }),

  persist: {
    paths: ['pins']
  },

  getters: {
    isPinned: ({ pins }) => (dashboardID) => pins.includes(dashboardID)
  },

  actions: {
    /**
     * Get the custom dashboards for an organization.
     * @param {Number} organizationID The organization ID.
     * @returns {Promise<Array<{ id: Number, name: String, tiles: Array<String>, created: Date, last_modified: Date|null }>>}
     */
    getDashboards(organizationID) {
      return new Promise((resolve) => {
        customDashboardsApi.getDashboards(organizationID).then((res) => {
          this.dashboards = res.data
          resolve(res.data)
        }, () => { })
      })
    },

    /**
     * Get a custom dashboard for an organization.
     * @param {Number} organizationID The organization ID.
     * @param {Number} dashboardID The dashboard ID.
     * @returns {Promise<{ id: Number, name: String, tiles: Array<String>, created: Date, last_modified: Date|null }>}
     */
    getDashboard(organizationID, dashboardID) {
      return new Promise((resolve) => {
        customDashboardsApi.getDashboard(organizationID, dashboardID).then((res) => {
          const dashboard = {
            ...res.data,
            tiles: res.data.tiles.map((tile) => JSON.parse(tile))
          }
          this.dashboard = dashboard
          resolve(dashboard)
        })
      })
    },

    /**
     * Create a custom dashboard for an organization.
     * @param {Number} organizationID The organization ID.
     * @param {{ name: String, tiles: Array<Object> }} payload
     * @returns {Promise<{ id: Number, name: String, tiles: Array<String>, created: Date, last_modified: Date|null }>}
     */
    createDashboard(organizationID, payload) {
      return new Promise((resolve, reject) => {
        customDashboardsApi.createDashboard(organizationID, payload).then((res) => {
          if (res.data) {
            resolve(res.data)
          } else {
            reject('Could not create custom dashboard.')
          }
        })
      })
    },

    /**
     * Path a custom dashboard.
     * @param {Number} organizationID
     * @param {Number} dashboardID
     * @param {{ name: String, tiles: Array<Object> }} payload
     * @returns {Promise<{ id: Number, name: String, tiles: Array<String>, created: Date, last_modified: Date|null }>}
     */
    patchDashboard(organizationID, dashboardID, payload) {
      return new Promise((resolve, reject) => {
        customDashboardsApi.patchDashboard(organizationID, dashboardID, payload).then((res) => {
          resolve(res.data)
        }, () => {
          reject('Could not patch custom dashboard.')
        })
      })
    },

    /**
     * Delete a custom dashboard.
     * @param {Number} organizationID
     * @param {Number} dashboardID
     * @returns {Promise}
     */
    deleteDashboard(organizationID, dashboardID) {
      return new Promise((resolve, reject) => {
        customDashboardsApi.deleteDashboard(organizationID, dashboardID).then(() => {
          this.togglePin(dashboardID, false)
          resolve()
        }, () => {
          reject('Could not delete custom dashboard.')
        })
      })
    },

    togglePin(dashboardID, append = true) {
      const index = this.pins.indexOf(dashboardID)
      if (index >= 0) {
        this.pins.splice(index, 1)
      } else if (append) {
        this.pins.push(dashboardID)
      }
    }
  }
})

export { useCustomDashboardStore }
