import { defineStore } from 'pinia'
// import instance from '@/api/index.js'
// import mainServerUrl from '@/api/modules/main-server/index.js'
import mappingApi from '@/api/modules/public/mapping/mapping.js'

export const useAppStore = defineStore({
  id: 'app',

  state: () => ({
    loading: {
      max: 0,
      val: 0
    },
    selectedCurrency: 'USD',
    conversionRates: {},
    networkErrors: [],
    useCache: false
  }),

  getters: {
    currencies: ({ conversionRates }) => [...Object.keys(conversionRates), 'USD'].sort()
  },

  // Temporarily remove selecting user's currency
  persist: {
    storage: localStorage,
    // paths: ['selectedCurrency']
    paths: ['useCache']
  },

  actions: {
    resetLoading() {
      this.loading = {
        max: 0,
        val: 0
      }
    },
    increaseMaxLoading(inc = 1) {
      this.loading.max += inc
    },
    increaseValLoading(inc = 1) {
      this.loading.val += inc
    },

    loadConversionRates() {
      return new Promise((resolve, reject) => {
        // We already have the conversion rates, resolve them directly.
        if (Object.keys(this.conversionRates).length) {
          return resolve(this.conversionRates)
        }
        // instance.post(mainServerUrl + '/conversion_rates').then(({ data }) => {
        mappingApi.getPublic('conversion_rates.json').then(({ data}) => {
          this.conversionRates = data
          resolve(data)
        }, () => reject('Could not load conversion rates.'))
      })
    },

    convertCurrency(value, fromCurrency = 'USD', toCurrency = null) {
      const { conversionRates, selectedCurrency } = this

      const from = fromCurrency || 'USD'
      const to = toCurrency || selectedCurrency

      if (from === to) {
        return value
      }

      let valueInUSD = from === 'USD' ? value : (value / conversionRates[from])

      if (to === 'USD') {
        return valueInUSD
      } else {
        return valueInUSD * conversionRates[to]
      }
    },

    /**
     * Generate a label for a tooltip, with value in selected currency, and value in original currency (if different).
     * @param amount
     * @param originalCurrency
     * @returns {*}
     */
    makeCurrencyLabel(amount, originalCurrency = null) {
      const { selectedCurrency } = this
      const t = `${selectedCurrency} ${amount}`
      const original = originalCurrency || selectedCurrency
      if (original === selectedCurrency) {
        return t
      }
      return `${t} (${original} ${this.convertCurrency(amount, selectedCurrency, original)})`
    },

    setSelectedCurrency(selectedCurrency) {
      this.selectedCurrency = selectedCurrency
    },

    addNetworkError(error) {
      const { message, config: { url, data: requestBody }, response } = error
      this.networkErrors.push({
        message,
        url,
        requestBody,
        response
      })
    }
  }
})
