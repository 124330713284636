import moment from 'moment'
import { getAWSNameFromTags } from '@/common/utils.js'

const format = 'MMM DD, YYYY'

export const formatDate = (date) => moment(date).format(format)

export const getDates = (data) =>
  Array.from(
    new Set(
      data.reduce((allDates, providerLine) => {
        if (!Array.isArray(providerLine.data) && !Array.isArray(providerLine.global)) {
          return allDates
        }

        const source =
          Array.isArray(providerLine.data) && providerLine.data.length
            ? providerLine.data
            : providerLine.global

        switch (providerLine.provider) {
          case 'aws':
            return allDates
              .concat(source.map((line) => line.TimePeriod.Start))
              .map((date) => moment(date).format(format))
          case 'azure':
            return allDates.concat(
              source.map(({ service_period_start_date }) =>
                moment(service_period_start_date).format(format)
              )
            )
          case 'gcp':
            return allDates
              .concat(source.map(({ entry_date }) => entry_date))
              .map((date) => moment(date).format(format))
          case 'oci':
            return allDates
              .concat(source.map(({ time_usage_started }) => moment(time_usage_started.split('T')[0]).format(format)))
          default:
            return allDates
        }
      }, [])
    )
  )

export const colors = [
  '#A3FFD6',
  '#71D9C7',
  '#8576FF',
  '#83A2FF',
  '#B4BDFF',
  '#A0E9FF',
  '#00A9FF',
  '#FFB4B4',
  '#FFD28F',
  '#FDF7C3',
  '#FFCEFE',
  '#D9ACF5',
  '#D67BFF'
]

export function getColor(i) {
  return colors[i % colors.length]
}

export function getColors(arr) {
  const result = []
  for (let i = 0; i < arr.length; i++) {
    result.push(getColor(i))
  }
  return result
}

export function getFiltersForView(view) {
  const filters = []
  const { rules } = view
  for (const rule in rules.filters) {
    filters.push({
      field: rule,
      condition: rules.filters[rule].operator,
      value: rules.filters[rule].value
    })
  }
  return filters
}

export function getResourceName(resourceID, provider, tags = null) {
  switch (provider) {
    case 'gcp':
      return resourceID.split('/').pop()
    case 'aws':
      return getAWSNameFromTags(resourceID, tags)
    case 'azure':
      return resourceID.split('/').pop()
    default:
      return resourceID
  }
}

export function getResourceID(resourceID, provider) {
  switch (provider) {
    case 'gcp': {
      const match = resourceID.match(/^\/\/\w+\.googleapis\.com\/(.*)/)
      if (match.length >= 2) {
        return match.pop()
      }
      return resourceID
    }
    default:
      return resourceID
  }
}

export function accruedCosts(values) {
  return values.reduce((acc, curr) => acc + curr, 0)
}

export function getFuckingAzureColumnsIndexes(data) {
  const cost = data.columns.findIndex(({ name }) => name === 'CostUSD')
  const date = data.columns.findIndex(({ name }) => name === 'UsageDate')
  const resourceID = data.columns.findIndex(({ name }) => name === 'ResourceId')
  const location = data.columns.findIndex(({ name }) => name === 'ResourceLocation')
  const service = data.columns.findIndex(({ name }) => name === 'ServiceName')
  const subscriptionID = data.columns.findIndex(({ name }) => name === 'SubscriptionId')
  const guid = data.columns.findIndex(({ name }) => name === 'ResourceGuid')
  const usageType = data.columns.findIndex(({ name }) => name === 'Meter')
  const currency = data.columns.findIndex(({ name }) => name === 'Currency')

  return {
    cost,
    date,
    resourceID,
    location,
    service,
    subscriptionID,
    guid,
    usageType,
    currency
  }
}

export function getAWSColumnsIndexes(data) {
  if (!Array.isArray(data)) {
    return null
  }

  const [columns] = data
  const resourceID = columns.indexOf('resource_id')
  const subaccount = columns.indexOf('account_id')
  const usageType = columns.indexOf('usage_type')
  const cost = columns.indexOf('cost')
  const date = columns.indexOf('date')
  const service = columns.indexOf('service')
  const currency = columns.indexOf('currency')
  const location = columns.indexOf('location')
  const tags = columns.indexOf('tags')

  return {
    resourceID,
    subaccount,
    usageType,
    cost,
    date,
    service,
    currency,
    location,
    tags
  }
}

export const businessMetricUnitScales = {
  unit: 1,
  hundred: 100,
  thousand: 1000,
  million: 1e6,
  billion: 1e9,
}
